import { EventEmitter, Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class TextEditorService {
  private _content: string = '';
  
  getContent: EventEmitter<string> = new EventEmitter<string>();
  contentChanged: EventEmitter<string> = new EventEmitter<string>();

  setContent(value: string): void {
    this._content = value;
    this.getContent.emit(this._content);
  }

  updateContent(value: string): void {
    this._content = value;
    this.contentChanged.emit(this._content);
  }

  get content(): string {
    return this._content;
  }
}
