import { Component, OnInit, ViewChild, inject } from "@angular/core";

import { DatalexClient, ICaseBE, ICaseContactBE, IContactBE, IDocumentBE, IDocumentLimitedBE, } from "@datalex-software-as/datalex-client";

import { FormsModule } from "@angular/forms";
import { QuillModule } from "ngx-quill";
import { TextEditorComponent } from "../../UI/text-editor/text-editor.component";
import { MicrosoftGraph } from "src/app/classes/MicrosoftGraph";
import { SystemCacheService } from "src/app/services/system-cache.service";
import { TextEditorService } from "../../UI/text-editor/text-editor.service";
import { EmailComposerComponent } from "../../UI/email-composer/email-composer.component";
import { firstValueFrom } from "rxjs";
import { all, endExpression } from "@igniteui/material-icons-extended";

const _PORT_ = 3000;
const _PROTOCOL_ = 'http';



@Component({
  selector: 'app-test-page',
  templateUrl: './test-page.component.html',
  styleUrls: ['./test-page.component.scss'],
  imports: [FormsModule, QuillModule, TextEditorComponent, EmailComposerComponent],
  standalone: true,
})
export class TestPageComponent implements OnInit {
  dlxClient = inject(DatalexClient);
  sys = inject(SystemCacheService);
  //   txtSvc = inject(TextEditorService);

  //   client: MicrosoftGraph | null = null;

  //   folders: any[] = [];

  contact: IContactBE | null = null;
  case: ICaseBE | null = null;
  documents: IDocumentLimitedBE[] = [];
  caseContacts: ICaseContactBE[] = [];


  ngOnInit(): void {


    try {
      this.initialize();
    } catch (error) {
      console.log(error);

      this.sys.isReady.subscribe(() => {
        this.initialize();
      })
    }

  }

  async initialize() {
    this.getInbox();
    try {
      this.case = await firstValueFrom(this.dlxClient.GetCase("e029113f-f951-4887-860a-d0c0c3a7c761", false));
      this.documents = await firstValueFrom(this.dlxClient.FindDocuments(null, null, "e029113f-f951-4887-860a-d0c0c3a7c761", null, null, null, [], null, null, null, "", "", "", null, "", null, null, null, null));
      this.caseContacts = await firstValueFrom(this.dlxClient.GetCaseContactsByCaseId("e029113f-f951-4887-860a-d0c0c3a7c761"))
    } catch (error) {

    }


  }


  getInbox() {

    if (!this.sys.microsoftGraphClientConfig) {
      throw new Error('Microsoft Graph Client Config not found in system cache');
    }

    const client = new MicrosoftGraph({
      authority: this.sys.microsoftGraphClientConfig.authority,
      clientId: this.sys.microsoftGraphClientConfig.clientId,
      scopes: this.sys.microsoftGraphClientConfig.clientScopes
    });



    try {
      client.initiateClient().then(async (status) => {
        const data: any[] = [];
        let nextUrl = (folder: string) => `/me/mailFolders/${folder}/messages`;
        const start = +new Date();
        const folders_Info = [];
        const groups_Info = [];
        const users_info = [];
        const availableMailboxes = [];
        const availableMailFolderForMailboxes = []

        const groups = await client.client?.api('/me/memberOf')
          .select("id,displayName,mail,mailEnabled")
          .get();

        for (const group of groups.value) {
          if (group.mailEnabled && group.mail) {
            groups_Info.push({ mailEnabled: group.mailEnabled, mail: group.mail });
          }
        }

        console.log(groups_Info);
        const users = await client.client?.api('/users').get();
        
        // const users = await client.client?.api('/users').get();
        // for (const user of users.value) {
        //   if (user.mail) {
        //     users_info.push(user);
        //     try {

        //       let messagesRequest = await client.client?.api(`/users/${user.id}/messages`).top(1).get();
        //       if (messagesRequest.value.length > 0) {

        //         const mailFolders = await client.client?.api(`/users/${user.id}/mailFolders`).get();

        //         for (const folder of mailFolders.value) {

        //           const message = await client.client?.api(`/users/${user.id}/mailFolders/${folder.id}/messages`).top(1).get();

        //           if(message.value.length > 0) {

        //             availableMailFolderForMailboxes.push({ user: user, folder: folder });

        //           }
        //         }
                
        //       }
        //     } catch (error) {

        //     }
        //   }        
        // }

        // console.log(availableMailFolderForMailboxes);


      // allMailboxes.forEach((mailbox) => {
      //   console.log("account:", mailbox.user.mail, "name:", mailbox.folder.displayName, "childCount:", mailbox.folder.childFolderCount)
      // })

        // try {
            
        //   let index = 0;
        //   const result: any = [];
        //   const currentSet: any[] = [];
        //   let currentUser = availableMailFolderForMailboxes[0].user.id;
        //   availableMailFolderForMailboxes.forEach((set) => {
        //     if(currentUser !== set.user.id) {
        //       index++;
        //       currentSet.splice(0, currentSet.length);
        //     } 
        //     result[index].push(set);
        //   })
  
        //   console.log(result);
        // } catch (error) {
        //   console.log(error)
        // }

        // console.log(result);



          
          let message1 = await client.client?.api(`/me/mailFolders/AAMkADk5YjBlOGI5LTkyNTMtNDBmNC1iNDkxLTBjMjc3NDZlMjc2MQAuAAAAAAAZFwJuEa13TqrF94vZMzt3AQBzos1t1suRRI3Od8mqy4arAAAAAKmOAAA=/messages`).top(1).select('subject, internetMessageId').get();
          console.log("monitis", message1.value[0].subject,  message1.value[0].internetMessageId);
          let message2 = await client.client?.api(`/me/mailFolders/AAMkADk5YjBlOGI5LTkyNTMtNDBmNC1iNDkxLTBjMjc3NDZlMjc2MQAuAAAAAAAZFwJuEa13TqrF94vZMzt3AQBzos1t1suRRI3Od8mqy4arAAAAAAEMAAA=/messages`).top(1).select('subject, internetMessageId').get();
          console.log('Innboks:', message2.value[0].subject,  message2.value[0].internetMessageId);
          
          
       
        
        // console.log(folders_Info);



        // folderIds.forEach(async folder => {
        //   while(nextUrl) {
        //     try {

        //       let messagesRequest = client.client?.api(nextUrl(folder))

        //       // Check if `$select` is already in `nextUrl`
        //       if (messagesRequest && !decodeURIComponent(nextUrl(folder)).includes('$select=')) {
        //           messagesRequest = messagesRequest.select('id, subject, bodyPreview, receivedDateTime');
        //       }

        //       if (messagesRequest && !decodeURIComponent(nextUrl(folder)).includes('$top=')) {
        //           messagesRequest = messagesRequest.top(50);
        //       }
        //       const datalexRef = '(Datalex ref:';
        //       const filterDate = '2024-10-01T00:00:00Z';
        //       if (messagesRequest && !decodeURIComponent(nextUrl(folder)).includes('$filter=')) {
        //         messagesRequest = messagesRequest.filter(`contains(subject, '${datalexRef}') and receivedDateTime ge ${filterDate}`);

        //       }



        //       const messages = await messagesRequest!.get();

        //       if(messages.value.length > 0) {
        //         console.log(messages.value);
        //         data.push(...messages.value);
        //       }
        //       nextUrl = messages['@odata.nextLink'] || undefined; 
        //     } catch (error) {

        //     }
        //   }
        //   console.log(data);
        // })









      })
    } catch (error) {

    }


    // try {
    //   client.initiateClient().then(async (status) => {

    //     // const messages = await client.client?.api('/me/messages').get()
    //     const driveId = 'b!fCJVS4YRLEKCQLACW8HhEbgZ77CR7yhNnYrT-_ipuMEpfcKbZmHlQathZcNoeMZ_'
    //     const itemPath = 'DATALEXTEST/Sak/0000004897/0000009838/000002/'
    //     const filename = 'oslo.pdf'

    //     try {
    //       const item = await client.client?.api(`/drives/${driveId}/root:/${itemPath}${filename}`).expand('listItem').get();
    //       debugger
    //       const fileId = item.eTag.split('"')[1].split(',')[0].replace('{','').replace('}','') as string;
    //       const siteId = item.listItem?.parentReference?.siteId.split(',')[1];
    //       const listId = item.listItem?.parentReference?.id;

    //       const webUrl = 'https://datalexsoftwareas.sharepoint.com';
    //       const fileName = filename;

    //       const user = await client.client?.api('/me').get();
    //       debugger
    //       const userEmail = user.mail;
    //       const userId = user.id;
    //       const odopenUrl = `odopen://openFile/?fileId=${fileId}&siteId={${siteId}}&listId={${listId}}&userEmail=${userEmail}&userId=${userId}&webUrl=${webUrl}&fileName=${fileName}`;
    //       console.log('ODOpen URL:', odopenUrl);
    //       window.open(odopenUrl, '_blank');
    //     } catch (error) {

    //     }
    //   })

    // } catch (error) {
    //   console.log(error);
    // }


  }






}


// API_URL = `${_PROTOCOL_}://localhost:${_PORT_}/api/`;
// dlxMsal = inject(DatalexMsalService);
// router = inject(Router)

// messages: IMessageLimited[] = [];
// selectedMessage: IMessageLimited | null = null;

// public navItems = [
//   { icon: 'inbox', name: "inbox", text: 'Innboks' },
//   { icon: 'drafts', name: "drafts", text: 'Kladd' },
//   { icon: 'send', name: "sent", text: 'Sendt' }
// ];

// public selected = 'Innboks';

// public navigate(item: { name: string, text: string }) {
//   this.selected = item.text;
//   this.router.navigate([`test/${item.name}`]);
// }

// driveId: string = "";
// itemId: string = "";
// msusers: any = [];
// selectedUsers: any = [];

// driveItem: any;

// ngOnInit() {
// }

// getDrives() {
//   this.dlxMsal.useToken(this.router.url).subscribe({
//     next: (token) => {
//       getDrives(this.API_URL, token).then((data) => {
//         console.log(data)
//       })

//     }

//   })
// }

// uploadFile(e: HTMLInputElement) {
//   this.dlxMsal.useToken(this.router.url).subscribe({
//     next: (token) => {
//       uploadFileToPersonalDrive(this.API_URL, token, e).then((data) => {
//         console.log(data)
//         this.itemId = data.id;
//         this.driveId = data.parentReference.driveId
//       })

//     }

//   })
// }
// uploadFile2(e: HTMLInputElement) {
//   this.dlxMsal.useToken(this.router.url).subscribe({
//     next: (token) => {
//       uploadFileToDrive(this.API_URL, token, e).then((data) => {
//         console.log(data)
//         this.itemId = data.id;
//         this.driveId = data.parentReference.driveId

//         this.driveItem = data;
//       })

//     }

//   })
// }

// deleteFile(){
//   this.dlxMsal.useToken(this.router.url).subscribe({
//     next: (token) => {
//       deleteFile(this.API_URL, token, this.itemId).then((data) => {
//         console.log(data)
//       })

//     }

//   })
// }
// openFile(){
//   console.log(this.driveItem)
//   window.open(`${applicationURL.ms_word_rw}https://datalexsoftwareas.sharepoint.com/SondreTemp/CaseNumber/DocumentNumber/${this.driveItem.name}`, '_blank');
// }

// getDriveItemPermissions() {
//   this.dlxMsal.useToken(this.router.url).subscribe({
//     next: (token) => {
//       getPermissions(this.API_URL, token, this.driveId, this.itemId).then((data) => {
//         console.log(data)
//       })

//     }

//   })
// }
// putDriveItemPermissions() {
//   this.dlxMsal.useToken(this.router.url).subscribe({
//     next: (token) => {
//       putPermissions(this.API_URL, token, this.driveId, this.itemId, [this.selectedUsers[0].id]).then((data) => {
//         console.log(data)
//       })

//     }

//   })
// }
// getMicrosoftUsers() {
//   this.dlxMsal.useToken(this.router.url).subscribe({
//     next: (token) => {
//       getUsers(this.API_URL, token).then((data) => {
//         this.msusers = data.value;
//       })

//     }

//   })
// }
// getMicrosoftGroups() {
//   this.dlxMsal.useToken(this.router.url).subscribe({
//     next: (token) => {
//       getGroups(this.API_URL, token).then((data) => {
//         data.value.forEach((group: any) => {
//           if (group.onPremisesDomainName === null) {
//             console.log(group)
//           }
//         })
//       })

//     }

//   })
// }