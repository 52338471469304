import { Component, inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatalexClient, ICaseBE, ICaseContactBE, IContactBE, IDocumentBE, IDocumentLimitedBE } from '@datalex-software-as/datalex-client';
import { SystemCacheService } from 'src/app/services/system-cache.service';
import { Attachment, EmailComposer, Recipient, SendMailRequest } from './EmailComposer';
import { TextEditorComponent } from "../text-editor/text-editor.component";
import { FormsModule } from '@angular/forms';
import { MicrosoftGraph } from 'src/app/classes/MicrosoftGraph';
import { DlxIconComponent } from "../dlx-icon/dlx-icon.component";


@Component({
  selector: 'dlx-email-composer',
  standalone: true,
  imports: [CommonModule, FormsModule, TextEditorComponent, DlxIconComponent],
  templateUrl: './email-composer.component.html',
  styleUrl: './email-composer.component.scss'
})
export class EmailComposerComponent implements OnInit {
  dlxClient = inject(DatalexClient);
  sys = inject(SystemCacheService);

  _composer = new EmailComposer();

  showparticipantsMore = false;

  constructor() {

  }

  ngOnInit(): void {
    try {
      this.initialize()
    } catch (error) {
      this.sys.isReady.subscribe({
        next: () => this.initialize(),
      })
    }
  }

  initialize() {
    this.from = { emailAddress: { address: this.sys.loggedinUserContact.EmailAddress, name: this.reorderName(this.sys.loggedinUserContact.Name) } };
  }



  @Input() contacts: ICaseContactBE[] | null = null;
  @Input() documents: IDocumentLimitedBE[] | null = null;
  _caseBe: ICaseBE | null = null;
  @Input() set caseBe(value: ICaseBE | null) {
    this._caseBe = value;
    this.saveOnCase = true;
    this.saveOnContact = false;
  }
  get caseBe() {
    return this._caseBe;
  }
  _contactBe: IContactBE | null = null;
  @Input() set contactBe(value: IContactBE | null) {
    this._contactBe = value;
    this.saveOnCase = false;
    this.saveOnContact = true;
  }



  from: Recipient = { emailAddress: { address: "", name: "" } };
  toRecipients: Recipient[] = [];
  ccRecipients: Recipient[] = [];
  bccRecipients: Recipient[] = [];
  requestDeliveredReceipt = false;
  requestReadReceipt = false;
  attachments: Attachment[] = [
    {
      name: "test.pdf", contentType: "application/pdf", contentBytes: "dGVzdA==", isInline: false,
      '@odata.type': '#microsoft.graph.fileAttachment'
    },
    {
      name: "test.pdf", contentType: "application/pdf", contentBytes: "dGVzdA==", isInline: false,
      '@odata.type': '#microsoft.graph.fileAttachment'
    },
    {
      name: "test.pdf", contentType: "application/pdf", contentBytes: "dGVzdA==", isInline: false,
      '@odata.type': '#microsoft.graph.fileAttachment'
    },
    {
      name: "test.pdf", contentType: "application/pdf", contentBytes: "dGVzdA==", isInline: false,
      '@odata.type': '#microsoft.graph.fileAttachment'
    }
  ];

  saveOnCase = false;
  saveOnContact = false;

  recipientLookUp(event: Event, type: 'to' | 'cc' | 'bcc') {
    const target = event.target as HTMLElement | null;

    if (target) {
      const userInput = target.innerText;
      const recipients = this.contacts?.filter(contact => {
        return contact.ContactName.toLowerCase().includes(userInput.trim().toLowerCase())
      }
      ) ?? [];


      // console.log(recipients);

      if (recipients.length === 1) {
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(recipients[0].Email)) return;

        switch (type) {
          case 'to':
            if (this.toRecipients.find(r => r.emailAddress.address === recipients[0].Email)) break;
            this.toRecipients.push({ emailAddress: { address: recipients[0].Email, name: this.reorderName(recipients[0].ContactName) } });
            target.innerText = "";
            break;
          case 'cc':
            if (this.ccRecipients.find(r => r.emailAddress.address === recipients[0].Email)) break;
            this.ccRecipients.push({ emailAddress: { address: recipients[0].Email, name: this.reorderName(recipients[0].ContactName) } });
            target.innerText = "";
            break;
          case 'bcc':
            if (this.bccRecipients.find(r => r.emailAddress.address === recipients[0].Email)) break;
            this.bccRecipients.push({ emailAddress: { address: recipients[0].Email, name: this.reorderName(recipients[0].ContactName) } });
            target.innerText = "";
            break;
        }


      }
    }
  }

  removeRecipient(event: Event, type: 'to' | 'cc' | 'bcc') {
    try {
      const target = event.target as HTMLElement;
      switch (type) {
        case 'to':
          this.toRecipients = this.toRecipients.filter(r => r.emailAddress.address !== target.parentElement?.dataset['email']);
          break;
        case 'cc':
          this.ccRecipients = this.ccRecipients.filter(r => r.emailAddress.address !== target.parentElement?.dataset['email']);
          break;
        case 'bcc':
          this.bccRecipients = this.bccRecipients.filter(r => r.emailAddress.address !== target.parentElement?.dataset['email']);
          break;
      }
    } catch (error) {
      console.error(error);
    }
  }

  removeAttachment(event: Event) {
    try {
      const target = event.target as HTMLElement;
      const index = target.parentElement?.dataset['index'];
      if (index === undefined) return;
      this.attachments = this.attachments.filter((a, i) => i !== parseInt(index));
    } catch (error) {
      console.error(error);
    }
  }

  setSubject(event: Event) {
    const target = event.target as HTMLDivElement;
    this._composer.setSubject({ subject: target.innerText });
  }


  showDocuments() {

  }

  addAttachment(doc: IDocumentLimitedBE) {
    this.attachments.push({
      name: doc.DocumentName,
      contentType: "",
      contentBytes: "",
      isInline: false,
      '@odata.type': '#microsoft.graph.fileAttachment'

    })
  }


  sendEmail() {
    this._composer
      .setFrom({ address: this.from.emailAddress.address, name: this.from.emailAddress.name })
      .addToRecipients(this.toRecipients.map(r => ({ address: r.emailAddress.address, name: r.emailAddress.name })))
      .addCcRecipients(this.ccRecipients.map(r => ({ address: r.emailAddress.address, name: r.emailAddress.name })))
      .requestReadReceipt({ request: this.requestReadReceipt })
      .requestDeliveryReceipt({ request: this.requestReadReceipt })
      .addBccRecipients(this.bccRecipients.map(r => ({ address: r.emailAddress.address, name: r.emailAddress.name })))
      .setBody({ content: "<p>Dette er en epost sendt fra <strong>DatalexWeb</strong></p>", contentType: 'HTML' });


    this.onSend(this._composer.getEmail());
  }

  logComponent() {
  }


  reorderName(fullName: string) {
    const nameParts = fullName.split(" ");
    if (nameParts.length > 1) {
      return [...nameParts.slice(1), nameParts[0]].join(" ");
    }
    return fullName;

  }


  onSend(sendMailRequest: SendMailRequest) {

    if (!this.sys.microsoftGraphClientConfig) {
      throw new Error('Microsoft Graph Client Config not found in system cache');
    }

    const client = new MicrosoftGraph({
      authority: this.sys.microsoftGraphClientConfig.authority,
      clientId: this.sys.microsoftGraphClientConfig.clientId,
      scopes: this.sys.microsoftGraphClientConfig.clientScopes
    });


    try {
      client.initiateClient().then(async (status) => {

        const sendt = await client.client?.api('/me/sendMail')
          .post(sendMailRequest);

      })
    } catch (error) {
      console.log(error);
    }


  }

}
