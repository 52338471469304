<section class="email-controls">
    <div class="email-controls-left-buttons">
        <div class="ribbon-send" (click)="sendEmail()">
            <dlx-icon iconName="send"/>
            <!-- <span translate="no" class="material-symbols-outlined">send</span> -->
            <span>Send</span>
        </div>

        <div class="add-attachment" (click)="showDocuments()">
            <span translate="no" class="material-symbols-outlined">attachment</span>
        </div>


    </div>
    <div class="participants">
        @if(showparticipantsMore) {
        <div class="recipientsField">FRA:
            <span class="from-recipient" contenteditable="false">{{from.emailAddress.name}}
                ({{from.emailAddress.address}})</span>
        </div>

        }
        <div class="recipientsField">TIL:
            @for (recipient of toRecipients; track $index) {
            <span class="recipient" contenteditable="false"
                [attr.data-email]="recipient.emailAddress.address">{{recipient.emailAddress.name}} <span translate="no"
                    class="material-symbols-outlined" (click)="removeRecipient($event, 'to')">close</span></span>
            }
            <div class="recipientsField-input" contenteditable="true" (input)="recipientLookUp($event, 'to')"></div>
        </div>

        <div class="recipientsField">CC:
            @for (recipient of ccRecipients; track $index) {
            <span class="recipient" contenteditable="false"
                [attr.data-email]="recipient.emailAddress.address">{{recipient.emailAddress.name}} <span translate="no"
                    class="material-symbols-outlined" (click)="removeRecipient($event, 'cc')">close</span></span>
            }
            <div class="recipientsField-input" contenteditable="true" (input)="recipientLookUp($event, 'cc')"></div>
        </div>

        @if(showparticipantsMore) {
        <div class="recipientsField">BCC:
            @for (recipient of bccRecipients; track $index) {
            <span class="recipient" contenteditable="false"
                [attr.data-email]="recipient.emailAddress.address">{{recipient.emailAddress.name}} <span translate="no"
                    class="material-symbols-outlined" (click)="removeRecipient($event, 'bcc')">close</span></span>
            }
            <div class="recipientsField-input" contenteditable="true" (input)="recipientLookUp($event, 'bcc')"></div>
        </div>
        <div class="email-controls-input-container">
            @if(contactBe) {
            <div class="email-controls-checkbox-wrapper">
                <label for="saveOnContact">Lagre på kontakt</label>
                <input id="saveOnContact" name="saveOnContact" type="checkbox" [(ngModel)]="saveOnContact">
            </div>
            }
            @if(caseBe) {
            <div class="email-controls-checkbox-wrapper">
                <label for="saveOnCase">Lagre på sak</label>
                <input id="saveOnCase" name="saveOnCase" type="checkbox" [(ngModel)]="saveOnCase">
            </div>
            }

            <div class="email-controls-checkbox-wrapper">
                <label for="requestDeliveredReceipt">Levert kvittering</label>
                <input id="requestDeliveredReceipt" name="requestDeliveredReceipt" type="checkbox"
                    [(ngModel)]="requestDeliveredReceipt">
            </div>
            <div class="email-controls-checkbox-wrapper">
                <label for="requestReadReceipt">Lest kvittering</label>
                <input id="requestReadReceipt" name="requestReadReceipt" type="checkbox"
                    [(ngModel)]="requestReadReceipt">
            </div>
            <button (click)="logComponent()">LOG</button>
        </div>
        }


        <div class="attachment-container">
            <div class="attachments">
                @for(attachment of attachments; track $index) {
                <div class="attachment" [attr.data-index]="$index">{{attachment.name}}<span translate="no"
                        class="material-symbols-outlined" (click)="removeAttachment($event)">close</span></div>
                }
            </div>
        </div>

    </div>

    <div class="ribbon-more" (click)="showparticipantsMore = !showparticipantsMore">
        <span translate="no" class="material-symbols-outlined">{{showparticipantsMore ? 'unfold_less' :
            'unfold_more'}}</span>
        <span>{{showparticipantsMore ? 'mindre' : 'mer'}}</span>
    </div>

</section>

<section class="email-controls">
    <div class="subjectField">Emne:
        <div class="subjectField-input" contenteditable="true" (input)="setSubject($event)"></div>
    </div>
</section>


<!-- @for(doc of documents; track $index) {
    <div class="spacer" (click)="addAttachment(doc)">{{doc.DocumentName}}</div>
    <br>
} -->

<app-text-editor />