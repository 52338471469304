<dlx-email-composer [contactBe]="contact" [caseBe]="case" [documents]="documents" [contacts]="caseContacts"/>


<!-- <div #editor id="editor"></div> -->
<!-- <div style="width: 100px;">
  @for (folder of folders; track $index) {
    <div>{{folder.displayName}}</div>
  }
</div>
 <app-text-editor (send)="onSend($event)" /> -->


<!-- <button dlxDocumentSearch (result)="logResults($event)">SØK</button> -->

<!-- <app-document-search (results)="logResults($event)"></app-document-search> -->
<!-- <igx-grid autoGenerate="false" [data]="results">
  <igx-column field="DocumentName" header="Tittel" [sortable]="true" dataType="string" />
</igx-grid> -->



<!-- <dlx-document-folders [useDemoData]="true" (documentClicked)="onDocumentClick($event)"/> -->

<!-- <igx-grid #grid>
  <igx-grid-toolbar>
    <igx-grid-toolbar-actions class="grid-toolbar-actions">
      <button igxButton dlxDocumentSearch (result)="grid.data = $event">Åpne søk</button>
    </igx-grid-toolbar-actions>
  </igx-grid-toolbar>
  <igx-column field="DocumentName"  header="Tittel"               [sortable]="true" dataType="string" />
  <igx-column field="DateChanged"   header="Sist endret"          [sortable]="true" dataType="dateTime" />
  <igx-column field="AuthorName"    header="Avsender / Forfatter" [sortable]="true" dataType="string" />
  <igx-column field="InUseBy"       header="Låst av"              [sortable]="true" dataType="string" />
  <igx-column field="DocNr"         header="Dok.nr."              [sortable]="true" dataType="string" />
  <igx-column field="RecipientName" header="Adressat"             [sortable]="true" dataType="string" />
</igx-grid> -->
<!-- <app-document-search (results)="logResults($event)" (clickEvent)="logResults([])"></app-document-search> -->


<!-- <div class="content-wrap"> -->
<!-- <igx-nav-drawer #drawer [pin]="true" [pinThreshold]="0">
      <ng-template igxDrawer>
        @for (item of navItems; track $index) {

            <a igxDrawerItem [active]="item.text === selected" igxRipple (click)="navigate(item)">
                <i class="material-symbols-outlined">{{ item.icon }}</i>
                <span>{{ item.text }}</span>
              </a>
        }
        <span igxDrawerItem igxIconButton="flat" (click)="drawer.toggle()">
            <i class="material-symbols-outlined">menu</i>
          </span>

      </ng-template>
      <ng-template igxDrawerMini>
        @for (item of navItems; track $index) {
            <span igxDrawerItem [active]="item.text === selected" igxRipple (click)="navigate(item)">
                <i class="material-symbols-outlined">{{ item.icon }}</i>
            </span>
        }
        <span igxDrawerItem igxIconButton="flat" (click)="drawer.toggle()">
            <i class="material-symbols-outlined">menu</i>
          </span>

      </ng-template>
    </igx-nav-drawer> -->
<!--
  <main>
    <form class="flex-col">
      <input #fileinput type="file" name="fileinput" id="fileinput">
      <input type="button" value="Upload File" (click)="uploadFile(fileinput)">
      <input type="button" value="Upload File 2" (click)="uploadFile2(fileinput)">
    </form>
    <button (click)="getDrives()">GET DRIVES</button>
    <button (click)="getDriveItemPermissions()">GET PERMISSIONS</button>
    <button (click)="putDriveItemPermissions()">SET PERMISSIONS</button>
    <button (click)="getMicrosoftUsers()">GET USERS</button>
    <button (click)="getMicrosoftGroups()">GET GROUPS</button>
    <button (click)="openFile()">OPEN FILE</button>
    <button (click)="deleteFile()">DELETE FILE</button>
    <div>
      <div>DriveId: {{driveId}}</div>
      <div>ItemId: {{itemId}}</div>
    </div>
    <div style="display: flex;">
      <div style="overflow: scroll; height: 400px;">
        <div>users</div>
        @for (item of msusers; track $index) {
        <div style="margin: 1rem;" (click)="selectedUsers.push(item)">
          <div>{{item.displayName}}</div>
          <div>{{item.id}}</div>
        </div>
        }
      </div>
      <div style="overflow: scroll; height: 400px;">
        <div>Selected users</div>
        @for (item of selectedUsers; track $index) {
        <div style="margin: 1rem;" (click)="selectedUsers.splice($index, 1)">
          <div>{{item.displayName}}</div>
          <div>{{item.id}}</div>
        </div>
        }
      </div>
    </div> -->



<!-- <router-outlet></router-outlet> -->
<!-- </main> -->
<!-- </div> -->

<!-- <igx-splitter [style.height]="'100%'">
    <igx-splitter-pane minSize="60px" size="100%" maxSize="180px">
       <div class="nav-button-wrapper">
            <button class="nav-menu-button">
                <span class="material-symbols-outlined icon">inbox</span>
                <span>Innboks</span>
          </button>
            <button class="nav-menu-button">
                <span class="material-symbols-outlined icon">draft</span>
                <span>Kladd</span>
          </button>
            <button class="nav-menu-button">
                <span class="material-symbols-outlined icon">send</span>
                <span>Sendt</span>
          </button>
       </div>
    </igx-splitter-pane>
    <igx-splitter-pane minSize='300px' size="100%" maxSize="450px">
        <div class="messages scrollbar">
            @for (message of messages; track $index) {
            <div class="message" (click)="selectedMessage = message">
                <div class="sender">{{message.sender.emailAddress.name || message.sender.emailAddress.address }}</div>
                <div class="subject">{{ message.subject }}</div>
                <div class="bodyPreview"> {{ message.bodyPreview }}</div>
            </div>
            }
        </div>
    </igx-splitter-pane>
    <igx-splitter-pane class="message-reader-pane">

        <div class="reader-wrapper">
            @if(selectedMessage) {
            <div class="message-content-header">
                <div class="message-content-header-subject">{{ selectedMessage.subject }}</div>
                <div class="message-content-header-sender">Fra: {{ selectedMessage.sender.emailAddress.name }} {{"\<"+selectedMessage.sender.emailAddress.address + "\>" }}>
                </div>
                <div class="message-content-header-recipients">
                    <span>Mottakere:</span>
                    @for (recipient of selectedMessage.toRecipients; track $index) {
                    <div class="recipient">{{ recipient.emailAddress.name || recipient.emailAddress.address }}</div>
                    }
                </div>
            </div>
            <div #messageContent class="message-content scrollbar">

                <div messageContent class="message-content-body" [innerHTML]="selectedMessage.body.content">
                </div>
            </div>
            }
        </div>
    </igx-splitter-pane>


</igx-splitter> -->


<!-- <textarea class="auto-expand-textarea" [(ngModel)]="text" (input)="autoGrow($event)">
</textarea> -->

<!-- <app-money-laundering-status /> -->