import { AfterViewInit, Component, inject, EventEmitter, Output, ViewChild } from '@angular/core';
import { QuillEditorComponent, QuillModule, QuillModules } from 'ngx-quill';
import { TextEditorService } from './text-editor.service';

@Component({
  selector: 'app-text-editor',
  standalone: true,
  imports: [QuillEditorComponent, QuillModule],
  templateUrl: './text-editor.component.html',
  styleUrl: './text-editor.component.scss'
})
export class TextEditorComponent implements AfterViewInit{
  txtSvc = inject(TextEditorService);


  @ViewChild('editor', { static: false }) editor!: QuillEditorComponent;
  QuillConfiguration: QuillModules = {}

  @Output() send: EventEmitter<any> = new EventEmitter<any>();

  participants = {
    from: 'sondre@datalexsoftware.no',
    to: 'espen@datalexsoftware.no',
    cc: 'christer@datalexsoftware.no'
  }

  sendEmail(){
    this.send.emit({content: this.content, participants: this.participants})
  }

  content: string | null = null;
  
  ngAfterViewInit(): void {

    this.editor.onContentChanged.subscribe((data) => {
      this.content = data.html
    })


    this.txtSvc.getContent.subscribe((data) => {
      this.editor.writeValue(data)
    })

    this.txtSvc.contentChanged.subscribe((data) => {
      this.editor.writeValue(data)
      
    })

  }
  
}
