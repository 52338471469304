export interface Recipient {
    emailAddress: {
        address: string;
        name?: string;
    };
}

export interface ItemBody {
    contentType: 'Text' | 'HTML';
    content: string;
}

export interface Attachment {
    "@odata.type": "#microsoft.graph.fileAttachment" | "#microsoft.graph.itemAttachment" | "#microsoft.graph.referenceAttachment";
    name: string;
    contentType: string;
    contentBytes?: string;
    contentId?: string;
    isInline?: boolean;
}

export interface Message {
    subject: string;
    body: ItemBody;
    toRecipients: Recipient[];
    ccRecipients?: Recipient[];
    bccRecipients?: Recipient[];
    attachments?: Attachment[];
    importance?: 'Low' | 'Normal' | 'High';
    replyTo?: Recipient[];
    from?: Recipient;
    isReadReceiptRequested?: boolean;
    isDeliveryReceiptRequested?: boolean;
}

export interface SendMailRequest {
    message: Message;
    saveToSentItems?: boolean;
}

export class EmailComposer {
    private message: Message = {
        subject: "",
        body: { contentType: "Text", content: "" },
        toRecipients: []
    };
    private saveToSentItems: boolean = true;

    constructor({contentType = 'HTML'}: {contentType?: 'Text' | 'HTML'} = {}) {
        this.message = {
            subject: "",
            body: { contentType: contentType, content: "" },
            toRecipients: []
        };
    }

    setSubject({ subject }: { subject: string }): EmailComposer {
        this.message.subject = subject;
        return this;
    }

    setBody({ content, contentType = 'Text' }: { content: string; contentType?: 'Text' | 'HTML' }): EmailComposer {
        this.message.body = { contentType, content };
        return this;
    }

    setFrom({ address, name }: { address: string; name?: string }): EmailComposer {
        this.message.from = { emailAddress: { address, name } };
        return this;
    }

    addToRecipient({ address, name }: { address: string; name?: string }): EmailComposer {
        this.message.toRecipients.push({ emailAddress: { address, name } });
        return this;
    }

    addToRecipients(recipients: { address: string; name?: string }[]): EmailComposer {
        const formattedRecipients = recipients.map(recipient => ({
            emailAddress: { address: recipient.address, name: recipient.name }
        }));
        this.message.toRecipients.push(...formattedRecipients);
        return this;
    }

    addCcRecipient({ address, name }: { address: string; name?: string }): EmailComposer {
        if (!this.message.ccRecipients) this.message.ccRecipients = [];
        this.message.ccRecipients.push({ emailAddress: { address, name } });
        return this;
    }

    addCcRecipients(recipients: { address: string; name?: string }[]): EmailComposer {
        if (!this.message.ccRecipients) this.message.ccRecipients = [];
        const formattedRecipients = recipients.map(recipient => ({
            emailAddress: { address: recipient.address, name: recipient.name }
        }));
        this.message.ccRecipients.push(...formattedRecipients);
        return this;
    }

    addBccRecipient({ address, name }: { address: string; name?: string }): EmailComposer {
        if (!this.message.bccRecipients) this.message.bccRecipients = [];
        this.message.bccRecipients.push({ emailAddress: { address, name } });
        return this;
    }

    addBccRecipients(recipients: { address: string; name?: string }[]): EmailComposer {
        if (!this.message.bccRecipients) this.message.bccRecipients = [];
        const formattedRecipients = recipients.map(recipient => ({
            emailAddress: { address: recipient.address, name: recipient.name }
        }));
        this.message.bccRecipients.push(...formattedRecipients);
        return this;
    }

    addAttachment({ name, contentType, contentBytes, isInline = false }: 
    { name: string; contentType: string; contentBytes: string; isInline?: boolean }): EmailComposer {
        if (!this.message.attachments) this.message.attachments = [];
        this.message.attachments.push({
            "@odata.type": "#microsoft.graph.fileAttachment",
            name,
            contentType,
            contentBytes,
            isInline
        });
        return this;
    }

    setImportance({ level }: { level: 'Low' | 'Normal' | 'High' }): EmailComposer {
        this.message.importance = level;
        return this;
    }

    setReplyTo({ address, name }: { address: string; name?: string }): EmailComposer {
        if (!this.message.replyTo) this.message.replyTo = [];
        this.message.replyTo.push({ emailAddress: { address, name } });
        return this;
    }

    setSaveToSentItems({ save }: { save: boolean }): EmailComposer {
        this.saveToSentItems = save;
        return this;
    }

    requestReadReceipt({ request }: { request: boolean }): EmailComposer {
        this.message.isReadReceiptRequested = request;
        return this;
    }
    requestDeliveryReceipt({ request }: { request: boolean }): EmailComposer {
        this.message.isDeliveryReceiptRequested = request;
        return this;
    }

    getEmail(): SendMailRequest {
        return {
            message: this.message,
            saveToSentItems: this.saveToSentItems
        };
    }
}
